import "./App.css";
import Admin from "./Pages/admin";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUpForm from "./components/Signup/signup";
import MainPage from "./Pages/mainPage";
import Header from "./components/Header/header";
import InfoPages from "./Pages/infoPages";
import WorkingOfPnf from "./components/WorkingOfPnf/workingOfPnf";
import About from "./components/About/about";
import EventList from "./components/Event/eventList";
import ContactUs from "./components/ContactUs/contactUs";
import ProfilePage from "./Pages/profilePage";
import EventPage from "./components/Event/eventPage";
import Login from "./components/Login/login";
import ProtectedRoute from "./Utils/protectedRoute";
import TermsAndConditions from "./components/Terms&Condition/terms&Condition";
import RefundPolicy from "./components/RefundPolicy/refundPolicy";
import Maintainance from "./components/maintainance/Maintainace";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/signup" element={<InfoPages content={<ProtectedRoute from={'login/Signup'}><SignUpForm /></ProtectedRoute>} />} />
          <Route path="/login" element={<InfoPages content={<ProtectedRoute  from={'login/Signup'}><Login /></ProtectedRoute>} />} /> */}
          <Route path="/" element={<Maintainance />}  />
          {/* <Route path="/" element={<InfoPages content={<MainPage />} />} /> */}
          {/* <Route path="/howwework" element={<InfoPages content={<WorkingOfPnf />} />} />
          <Route path="/about" element={<InfoPages content={<About />} />} />
          <Route path="/events" element={<InfoPages content={<EventList />}/>} />
          <Route path="/eventdetail" element={<ProtectedRoute><InfoPages content={<EventPage />} /></ProtectedRoute>} />
          <Route path="/contactus" element={<InfoPages content={<ContactUs />} />} />
          <Route path="/profile" element={<InfoPages content={<ProfilePage />} />} />
          <Route path="/terms&condition" element={<InfoPages content={<TermsAndConditions />} />} />
          <Route path="/refundpolicy" element={<InfoPages content={<RefundPolicy />} />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
