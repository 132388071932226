import React, { useState } from "react";
import "./bookingModal.css";
import { Link, useNavigate } from "react-router-dom";
import useRegisterUserEvent from "../../Queries/useRegisterUserEvent";
import axios from "axios";
import { toast } from "react-toastify";

const BookingModal = ({ isOpen, onClose , eventDetails}) => {
//   const [otp, setOtp] = useState(["", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [errorText, setErrorText] = useState("");
    const navigate = useNavigate()
//   const handleChange = (index, value, e) => {
//     // Ensure the value is a digit
//     if (/^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);
//       if(value !== "" && index<3){

//         document.getElementById(`otp-input-${index + 1}`).focus();
//       }
//       //  handleKeyDown(index,e);

//     }
//   };

  const handleCheck = (e) => {
    console.log(e.target.checked)
    setIsAgree(e.target.checked)
  };

//   const handleClose = () => {
//     // Clear the OTP input on modal close
//     setOtp(["", "", "", ""]);
//     onClose();
//   };
  const submitEvent = useRegisterUserEvent();
  const registerUser = async () => {
    // Perform validation or submit the OTP
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("profileData"))
    const eventData = {
        "Gender": userData?.Gender,
        "MobileNumber": userData?.MobileNumber,
        "Name": userData?.FirstName + " " + userData?.LastName,
        "EventId": eventDetails?.type,
        "isAgreed": isAgree
    };
    if (isAgree) {
    //   await submitEvent.mutateAsync(eventData, {
    //     onError: function (e) {
    //         console.log(e)
    //         setIsLoading(false)
    //     },
    //     onSuccess: function () {
    //         setIsRegistered(true)      
    //     setIsLoading(false);
    //       onClose();
    //     },
    //   });

    axios
    .post(
        "https://pair-n-fork-service.vercel.app/api/event/addRegister",
      eventData,
      {
        headers: { Authorization: `Bearer ${userData?.accessToken}` },
      }
    )
    .then((res) => {
    console.log(res)
      if (res.data.success) {
        setIsRegistered(true)      
            setIsLoading(false);

              toast.success("You Have Registered For the Event")
      } else {
            setIsLoading(false)
      }
    })
    .catch((err) => {
      console.log(err?.response?.data?.message);
      if(err?.response?.data?.message === "Already Registered For the Event"){
          toast.success("Already Registered For the Event")
          setIsRegistered(true)   
      }
      if( err?.response?.data?.message ==="Not authorized, token invalid")
      {
        localStorage.clear()
        toast.error("session expired please login again")
        navigate("/login")
      }
      setIsLoading(false)
    });

    }
    // You can add your logic for OTP validation or submission here
    // console.log("Entered OTP:", "");
  };

  return (
    <div className={`booking-modal ${isOpen ? "open" : "closed"}`}>
      <div className="booking-modal-content">
      
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        {!isRegistered ? (<>
        <h4>You are registering for the event :- <br/>{eventDetails?.title}  </h4>
        {/* <h6>submit to get yourself registered {"eventName"} </h6> */}
        <div className="d-flex justify-content-center align-items-center">
        <input type="checkbox" checked={isAgree} onChange={(e) => handleCheck(e)} />
        <label style={{marginLeft:"5px", fontWeight:"normal"}}>I agree to <Link to="/terms&condition">Terms & Conditions </Link></label>
        </div>

        <button  className={isAgree ? "" : "disabled"} disabled={!isAgree} onClick={registerUser}>{isLoading?"loading...":"Submit"}</button>
        </>)
       : (<>
        <h2>You have registered successfully. Our team will contact you shortly</h2>
        <button onClick={onClose}> Close </button>
        </>)}
      </div>
    </div>
  );
};

export default BookingModal;
